/* this file is transformed by vux-loader */
/* eslint-disable */
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    msg: {
      type: String,
      default: function _default() {
        return '';
      }
    },
    title: {
      type: String,
      default: function _default() {
        return ' ';
      }
    },
    btn: {
      type: String,
      default: function _default() {
        return '重新采集';
      }
    }
  },
  data: function data() {
    return {
      img_src: require('@/assets/face/position_fail.png'),
      show: true,
      message: ''
    };
  },
  watch: {
    msg: {
      handler: function handler(newMsg) {
        this.message = newMsg;
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    cancel: function cancel() {
      this.$emit('closeDialog');
    },
    repeatClick: function repeatClick() {
      this.$emit('repeatClick');
    }
  },
  created: function created() {},
  mounted: function mounted() {}
};