/* this file is transformed by vux-loader */
/* eslint-disable */
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    btnMsg: {
      type: String,
      default: function _default() {
        return '确认删除';
      }
    },
    msg: {
      type: String,
      default: function _default() {
        return '确定删除该照片?';
      }
    },
    title: {
      type: String,
      default: function _default() {
        return '确认删除信息';
      }
    }
  },
  data: function data() {
    return {
      img_src: require('@/assets/face/danger.png'),
      show: true,
      message: '' // title:'确认删除信息',
      // msg:'确定删除该照片?',

    };
  },
  methods: {
    cancel: function cancel() {
      this.$emit('closeDialog');
    },
    del: function del() {
      this.$emit('delImg');
    }
  },
  created: function created() {},
  mounted: function mounted() {}
};