/* this file is transformed by vux-loader */
/* eslint-disable */
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import face from '@/components/Face/upFace';
import { Toast, Dialog, Notify } from 'vant';
import 'swiper/css/swiper.css';
import commonSuccess from "@/components/commonSuccess/index.vue";
import commonFaile from "@/components/commonFaile";
export default {
  components: {
    face: face,
    //上传图片的组件
    commonSuccess: commonSuccess,
    commonFaile: commonFaile
  },
  data: function data() {
    return {
      isShow: false,
      msg: '上传成功',
      isError: false,
      errorMsg: '',
      //错误信息内容
      errorTitle: '',
      //错误信息头部标题
      checkedDA: false,
      checkedPerson: false,
      selectArr: [{
        id: 1,
        src: require('../../assets/face/da.png'),
        checked: true,
        title: '作为档案照片'
      }, {
        id: 2,
        src: require('../../assets/face/renl.png'),
        checked: true,
        title: '作为人脸照片'
      }],
      imgArr: [],
      // 人脸照片数组
      userInfo: {},
      //人员的资料
      delArrimg: [],
      //删除的数组
      selectFace: true,
      selectDa: true,
      uploadCurrentImg: -1,
      uploadCurrentIndex: null,
      currentImgId: '',
      //存当前图片序号
      isScan: false,
      //质量检测
      faceImg: '',
      id: 1,
      isUploadImg: false,
      show: false,
      ImgIcon: require('../../assets/face/yuangong.png'),
      takePhotoInfo: null,
      reSet: '',
      //是否修改照片 0不允许
      userFace: '',
      userPhoto: '',
      memberIcon: ''
    };
  },
  watch: {
    isShow: {
      handler: function handler(newShow) {
        var _this = this;

        if (newShow) {
          setTimeout(function () {
            _this.isShow = false;
          }, 2000);
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    caijiphoto: function caijiphoto() {
      // 修复一个bug
      if (this.reSet == 0 && (this.imgArr[0] != null || this.imgArr[1] != null)) {
        Toast('当前设定禁止修改照片');
      } else {
        this.$utils.Store.set('faceIndex', null);
        this.$router.push({
          name: '开始采集',
          query: {
            show: true
          }
        });
      }
    },
    getValue: function getValue() {
      var _this2 = this;

      this.$api.Attence.getKqSettting().then(function (res) {
        // userFace 人脸    userPhoto档案
        _this2.userFace = res.data.userFace;
        _this2.userPhoto = res.data.userPhoto; // 0 档案照片   1人脸照片
        // 获取是否允许修改照片按钮

        _this2.reSet = res.data.reSet;
      });
    },
    //关闭弹窗
    closeDialgMsg: function closeDialgMsg() {
      this.isShow = false;
    },
    closeDialog: function closeDialog() {
      this.show = false;
    },
    //关闭弹窗
    closeDialogFail: function closeDialogFail() {
      this.isError = false;
      this.$utils.Store.set('faceIndex', null);

      if (+this.uploadCurrentIndex == 0 || +this.uploadCurrentIndex == 1) {
        this.$utils.Store.set('faceIndex', null);
      }
    },
    //重新采集
    repeatClick: function repeatClick() {
      if (this.uploadCurrentIndex != null && (+this.uploadCurrentIndex == 0 || +this.uploadCurrentIndex == 1)) this.$router.push({
        name: '开始采集',
        query: {
          show: false
        }
      });else {
        this.$router.push({
          name: '开始采集',
          query: {
            show: true
          }
        });
      }
    },
    checkDangAn: function checkDangAn(e, index) {
      if (index == 0 && e.checked) {
        this.selectDa = true;
      } else if (index == 0 && !e.checked) {
        this.selectDa = false;
      } else if (index == 1 && e.checked) {
        this.selectFace = true;
      } else if (index == 1 && !e.checked) {
        this.selectFace = false;
      }
    },
    //点击上传某张照片
    uploadCurrent: function uploadCurrent(item) {
      //userFace&&userPhoto
      if (this.reSet == 0 && this.userFace && this.userPhoto) {
        if (this.imgArr[0] != null || this.imgArr[1] != null) {
          this.$utils.Store.set('faceIndex', item); //item为0修改的为人脸  item为1修改的为档案

          this.currentImgId = item;
          this.$router.push({
            name: '开始采集',
            query: {
              show: false,
              xiugaizhaopian: this.currentImgId
            }
          });
        }

        return;
      }

      if (this.reSet == 0 && this.userFace && this.imgArr[0] != null) {
        Toast('当前设定禁止修改照片');
        return;
      }

      if (this.reSet == 0 && this.userPhoto && this.imgArr[1] != null) {
        Toast('当前设定禁止修改照片');
        return;
      } // this.uploadCurrentIndex = item


      this.$utils.Store.set('faceIndex', item); //item为0修改的为人脸  item为1修改的为档案

      this.currentImgId = item;
      this.$router.push({
        name: '开始采集',
        query: {
          show: false,
          xiugaizhaopian: this.currentImgId
        }
      });
    },
    tijiao: function tijiao() {
      if (!this.selectArr[0].checked && !this.selectArr[1].checked) {
        return Notify({
          type: 'warning',
          message: '请选择照片类型'
        });
      } else {
        var params = {
          userSerial: this.userSerial
        };

        if (this.selectDa) {
          params.userFilePhoto = this.takePhotoInfo.serverId;
        }

        if (this.selectFace) {
          params.userFacePhoto = this.takePhotoInfo.serverId;
        }

        this.uploadImg(params);
      }

      this.show = false;
    },
    //上传照片
    uploadImg: function uploadImg(params) {
      var _this3 = this;

      this.$api.User.addFacePhoto(params).then(function (res) {
        if (+res.code == 600) {
          _this3.isShow = true;

          _this3.getUserInfo();
        } else {
          _this3.$utils.Store.set('serverId', null);

          _this3.isError = true;
          _this3.errorTitle = "采集失败";
          _this3.errorMsg = res.msg;
        }
      });
    },
    setaginFlag: function setaginFlag(val, num) {
      var _this4 = this;

      //删除-
      var DelTitle = '';

      if (val) {
        //第一张是档案
        DelTitle = num == 1 && '是否删除档案照片' || num == 2 && '是否删除人脸照片';
        this.delArrimg = num == 1 && [1] || num == 2 && [2];
      } else {
        DelTitle = num == 1 && '是否删除人脸照片' || num == 2 && '是否删除档案照片';
        this.delArrimg = num == 1 && [2] || num == 2 && [1];
      }

      Dialog.confirm({
        title: DelTitle
      }).then(function () {
        _this4.dtImg(num, _this4.delArrimg);
      }).catch(function () {// on cancel
      });
    },

    /*
      子组件调用该方法
    */
    selectImg: function selectImg(item) {
      /* 大佬后台写的代码1是人脸 2是档案 */
      //num 0为下标
      this.dtImg(item);
    },
    dtImg: function dtImg(num) {
      var _this5 = this;

      this.$utils.Tools.loadShow();
      /* 大佬后台写的代码1  是删档案照片   2  是删人脸照片 */

      var delFace = 0;

      if (num == 0) {
        delFace = 2; //删除人脸
      } else if (num == 1) {
        delFace = 1; //删除档案
      }

      this.$api.User.delImg({
        userSerial: this.userSerial,

        /* 大佬后台写的代码1  是删档案照片   2  是删人脸照片 */
        chooseLx: [delFace]
      }).then(function (response) {
        _this5.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg;

        if (+code !== _this5.$code.success) {
          _this5.$utils.Tools.toastShow(msg);
        } else {
          _this5.getUserInfo();
        }
      }).catch(function () {
        _this5.$utils.Tools.httpError();
      });
    },
    getUserInfo: function getUserInfo() {
      var _this6 = this;

      this.$utils.Tools.loadShow();
      this.$api.User.getUser(this.userSerial).then(function (response) {
        _this6.$utils.Tools.loadHide();

        _this6.$utils.Store.set('serverId', null);

        var data = response.data;
        _this6.userInfo = data;
        _this6.memberIcon = data.userPhotoImg ? data.userPhotoImg : _this6.ImgIcon;

        _this6.$set(_this6.imgArr, 0, data.userFaceImg);

        _this6.$set(_this6.imgArr, 1, data.userPhotoImg);
      }).catch(function () {
        _this6.$utils.Tools.httpError();
      });
    },
    showError: function showError(item) {
      this.isError = true;
      this.errorTitle = "采集失败";
      this.errorMsg = item.msg;
    },
    //质量检测接口
    showFaceQuality: function showFaceQuality(item) {
      var _this7 = this;

      this.$api.Sys.faceQuality({
        userFacePhoto: item.serverId
      }).then(function (res) {
        _this7.isScan = false;

        if (+res.code == 600) {
          //   this.showImg(item)
          if (item.isFaceCheck == 1) {
            //1:只开启质量检测 和人脸识别
            _this7.showImg(item.serverId);
          } else if (item.isFaceCheck == 2) {
            //2：启用人脸身份核验 checkUserFace
            _this7.checkUserFace(item.serverId);
          }
        } else {
          _this7.isError = true;
          _this7.errorTitle = "照片采集失败";
          _this7.errorMsg = res.msg;

          _this7.$utils.Store.set('serverId', null);
        }
      });
    },
    //人脸1：1
    checkUserFace: function checkUserFace(item) {
      var _this8 = this;

      var param = {
        userSerial: this.$utils.Store.getItem('userSerial'),
        userFacePhoto: item,
        channel: 0
      };
      this.$api.Sys.checkUserFace(param).then(function (res) {
        if (res.code == 600) {
          _this8.showImg(item);
        } else {
          //失败
          _this8.isError = true;
          _this8.errorTitle = "采集失败";
          _this8.errorMsg = res.msg;
        }
      });
    },
    showImg: function showImg(item) {
      var _this9 = this;

      this.$api.Sys.getWxImageUrl(item, this.$params.mode).then(function (res) {
        if (+res.code == 600 && !_this9.uploadCurrentImg) {
          var params = {
            userSerial: _this9.userSerial
          };

          if (+_this9.uploadCurrentIndex == 0) {
            params.userFacePhoto = item;
          } else if (+_this9.uploadCurrentIndex == 1) {
            params.userFilePhoto = item;
          }

          _this9.uploadImg(params);
        } else {
          _this9.show = true;
        }
      });
    }
  },
  created: function created() {
    this.userSerial = this.$utils.Store.getItem('userSerial');
    this.getUserInfo();
    this.getValue();
    this.isUploadImg = false;
  },
  mounted: function mounted() {
    if (this.$utils.Store.get('serverId')) {
      // 获取用户id
      var param = this.$utils.Store.get('serverId');
      this.takePhotoInfo = param;
      this.uploadCurrentIndex = this.$utils.Store.get('faceIndex');
      this.uploadCurrentImg = param.show;

      if (param.isKaiqi) {
        this.isScan = true; // this.showFaceQuality(param.serverId)

        this.showFaceQuality(param);
      } else this.showImg(param.serverId);
    }
  }
};