/* this file is transformed by vux-loader */
/* eslint-disable */
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { on } from "@/utils/common";
import commonDel from '../commonDel';
export default {
  name: 'FaceTake',
  props: {
    imgArr: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  components: {
    commonDel: commonDel
  },
  data: function data() {
    return {
      result: [],
      userSerial: '',
      userInfo: {},
      faceSrc: '',
      delFlag: '0',
      serverId: '',
      clickCurrentImg: null,
      isShowDel: true,
      successImg: require('@/assets/face/success.png'),
      errorImg: require('@/assets/face/error.png'),
      noFaceImg: require('@/assets/face/renlian.png'),
      cameraImg: require('@/assets/camera.png'),
      isDel: false,
      currentIndex: null,
      isShanchu: '',
      userFace: '',
      userPhoto: ''
    };
  },
  watch: {},
  methods: {
    getflag: function getflag() {
      this.delFlag = '0';
      this.faceSrc = '';
      this.serverId = '';
      var boolean = {
        is: false
      };
      this.$emit('srcImg', this.faceSrc, this.serverId, boolean);
    },
    updataFace: function updataFace(index) {
      this.clickCurrentImg = index;
      this.$emit('uploadCurrent', index);
    },
    uploadImg: function uploadImg(item, index) {
      if (item) {
        return;
      }

      this.clickCurrentImg = index;
      this.$emit('uploadCurrent', index);
    },
    delImg: function delImg() {
      this.isDel = false; //调用父组件方法

      this.$emit('selectImg', this.currentIndex);
    },
    del: function del(index) {
      /* 删除下标 */
      if (this.isShanchu == 0) {
        this.$utils.Tools.toastShow('当前禁止修改照片');
      } else {
        this.isDel = true; //弹出对话框

        this.currentIndex = index;
      }
    },
    getValue: function getValue() {
      var _this = this;

      this.$api.Attence.getKqSettting().then(function (res) {
        // 0 禁止   1允许
        _this.isShanchu = res.data.reSet;
        _this.userFace = res.data.userFace;
        _this.userPhoto = res.data.userPhoto;
      });
    }
  },
  created: function created() {
    this.userSerial = this.$utils.Store.getItem('userSerial'); // this.getUserInfo();

    this.getValue();
  },
  mounted: function mounted() {
    var _this2 = this;

    on('uploadImgArr', function (msg) {
      _this2.imgArr = msg;
    });
  }
};